import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _90336550 = () => interopDefault(import('../src/pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _24b1918b = () => interopDefault(import('../src/pages/delivery-enquete/index.vue' /* webpackChunkName: "pages/delivery-enquete/index" */))
const _55a30c8e = () => interopDefault(import('../src/pages/inflow/index.vue' /* webpackChunkName: "pages/inflow/index" */))
const _7fe26e9a = () => interopDefault(import('../src/pages/integrate-user-error.vue' /* webpackChunkName: "pages/integrate-user-error" */))
const _5cfcb877 = () => interopDefault(import('../src/pages/line-account-renewal.vue' /* webpackChunkName: "pages/line-account-renewal" */))
const _104285a6 = () => interopDefault(import('../src/pages/location.vue' /* webpackChunkName: "pages/location" */))
const _d4821e14 = () => interopDefault(import('../src/pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _d3086b66 = () => interopDefault(import('../src/pages/not-authorized.vue' /* webpackChunkName: "pages/not-authorized" */))
const _324ffe7c = () => interopDefault(import('../src/pages/out-of-area.vue' /* webpackChunkName: "pages/out-of-area" */))
const _7cacd3d5 = () => interopDefault(import('../src/pages/pit-reservation/index.vue' /* webpackChunkName: "pages/pit-reservation/index" */))
const _0beb10e2 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _605e47bc = () => interopDefault(import('../src/pages/quotations/index.vue' /* webpackChunkName: "pages/quotations/index" */))
const _3fc7fc1a = () => interopDefault(import('../src/pages/receipt/index.vue' /* webpackChunkName: "pages/receipt/index" */))
const _ea78e182 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _0a6060db = () => interopDefault(import('../src/pages/tire-guarantee.vue' /* webpackChunkName: "pages/tire-guarantee" */))
const _4927e1ca = () => interopDefault(import('../src/pages/tire-repair.vue' /* webpackChunkName: "pages/tire-repair" */))
const _18e2d69e = () => interopDefault(import('../src/pages/under-construction.vue' /* webpackChunkName: "pages/under-construction" */))
const _b13edc3c = () => interopDefault(import('../src/pages/unfollow-account.vue' /* webpackChunkName: "pages/unfollow-account" */))
const _2ac25ee0 = () => interopDefault(import('../src/pages/delivery-enquete/complete.vue' /* webpackChunkName: "pages/delivery-enquete/complete" */))
const _2c003b96 = () => interopDefault(import('../src/pages/mypage/edit.vue' /* webpackChunkName: "pages/mypage/edit" */))
const _0a02c3b9 = () => interopDefault(import('../src/pages/mypage/purchases/index.vue' /* webpackChunkName: "pages/mypage/purchases/index" */))
const _1002d0b6 = () => interopDefault(import('../src/pages/mypage/quotations/index.vue' /* webpackChunkName: "pages/mypage/quotations/index" */))
const _f7eaffd4 = () => interopDefault(import('../src/pages/mypage/vehicles/index.vue' /* webpackChunkName: "pages/mypage/vehicles/index" */))
const _6ae5f206 = () => interopDefault(import('../src/pages/pit-reservation/complete.vue' /* webpackChunkName: "pages/pit-reservation/complete" */))
const _082f3c03 = () => interopDefault(import('../src/pages/pit-reservation/confirm.vue' /* webpackChunkName: "pages/pit-reservation/confirm" */))
const _1d8c2a70 = () => interopDefault(import('../src/pages/pit-reservation/register.vue' /* webpackChunkName: "pages/pit-reservation/register" */))
const _0960e181 = () => interopDefault(import('../src/pages/pit-reservation/situation.vue' /* webpackChunkName: "pages/pit-reservation/situation" */))
const _11a80464 = () => interopDefault(import('../src/pages/pit-reservation/store.vue' /* webpackChunkName: "pages/pit-reservation/store" */))
const _95fd0344 = () => interopDefault(import('../src/pages/pit-reservation/work.vue' /* webpackChunkName: "pages/pit-reservation/work" */))
const _cdf02ace = () => interopDefault(import('../src/pages/quotations/complete.vue' /* webpackChunkName: "pages/quotations/complete" */))
const _d67640dc = () => interopDefault(import('../src/pages/quotations/details.vue' /* webpackChunkName: "pages/quotations/details" */))
const _9be4b1b0 = () => interopDefault(import('../src/pages/receipt/complete.vue' /* webpackChunkName: "pages/receipt/complete" */))
const _4c8b5aa1 = () => interopDefault(import('../src/pages/receipt/confirm.vue' /* webpackChunkName: "pages/receipt/confirm" */))
const _f72b756c = () => interopDefault(import('../src/pages/receipt/enquete.vue' /* webpackChunkName: "pages/receipt/enquete" */))
const _271987d3 = () => interopDefault(import('../src/pages/receipt/qr/index.vue' /* webpackChunkName: "pages/receipt/qr/index" */))
const _64b3df92 = () => interopDefault(import('../src/pages/receipt/register.vue' /* webpackChunkName: "pages/receipt/register" */))
const _2a17e108 = () => interopDefault(import('../src/pages/vehicles/registration/index.vue' /* webpackChunkName: "pages/vehicles/registration/index" */))
const _4917dacc = () => interopDefault(import('../src/pages/mypage/pit-reservation/complete.vue' /* webpackChunkName: "pages/mypage/pit-reservation/complete" */))
const _0bd0b8ef = () => interopDefault(import('../src/pages/mypage/pit-reservation/confirm.vue' /* webpackChunkName: "pages/mypage/pit-reservation/confirm" */))
const _aad25926 = () => interopDefault(import('../src/pages/mypage/pit-reservation/situation.vue' /* webpackChunkName: "pages/mypage/pit-reservation/situation" */))
const _82f3cab6 = () => interopDefault(import('../src/pages/receipt/qr/read.vue' /* webpackChunkName: "pages/receipt/qr/read" */))
const _c3467e02 = () => interopDefault(import('../src/pages/vehicles/registration/complete.vue' /* webpackChunkName: "pages/vehicles/registration/complete" */))
const _019629ea = () => interopDefault(import('../src/pages/vehicles/registration/confirm.vue' /* webpackChunkName: "pages/vehicles/registration/confirm" */))
const _00fd4467 = () => interopDefault(import('../src/pages/vehicles/registration/qr.vue' /* webpackChunkName: "pages/vehicles/registration/qr" */))
const _5902d3d6 = () => interopDefault(import('../src/pages/vehicles/registration/select-type.vue' /* webpackChunkName: "pages/vehicles/registration/select-type" */))
const _3e745dec = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _48f49a69 = () => interopDefault(import('../src/pages/mypage/pit-reservation/_id.vue' /* webpackChunkName: "pages/mypage/pit-reservation/_id" */))
const _44b06061 = () => interopDefault(import('../src/pages/mypage/purchases/_id.vue' /* webpackChunkName: "pages/mypage/purchases/_id" */))
const _df4ceec4 = () => interopDefault(import('../src/pages/mypage/quotations/_id.vue' /* webpackChunkName: "pages/mypage/quotations/_id" */))
const _0f458ffe = () => interopDefault(import('../src/pages/mypage/vehicles/_id.vue' /* webpackChunkName: "pages/mypage/vehicles/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact/",
    component: _90336550,
    pathToRegexpOptions: {"strict":true},
    name: "contact"
  }, {
    path: "/delivery-enquete/",
    component: _24b1918b,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete"
  }, {
    path: "/inflow/",
    component: _55a30c8e,
    pathToRegexpOptions: {"strict":true},
    name: "inflow"
  }, {
    path: "/integrate-user-error/",
    component: _7fe26e9a,
    pathToRegexpOptions: {"strict":true},
    name: "integrate-user-error"
  }, {
    path: "/line-account-renewal/",
    component: _5cfcb877,
    pathToRegexpOptions: {"strict":true},
    name: "line-account-renewal"
  }, {
    path: "/location/",
    component: _104285a6,
    pathToRegexpOptions: {"strict":true},
    name: "location"
  }, {
    path: "/mypage/",
    component: _d4821e14,
    pathToRegexpOptions: {"strict":true},
    name: "mypage"
  }, {
    path: "/not-authorized/",
    component: _d3086b66,
    pathToRegexpOptions: {"strict":true},
    name: "not-authorized"
  }, {
    path: "/out-of-area/",
    component: _324ffe7c,
    pathToRegexpOptions: {"strict":true},
    name: "out-of-area"
  }, {
    path: "/pit-reservation/",
    component: _7cacd3d5,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation"
  }, {
    path: "/privacy-policy/",
    component: _0beb10e2,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy"
  }, {
    path: "/quotations/",
    component: _605e47bc,
    pathToRegexpOptions: {"strict":true},
    name: "quotations"
  }, {
    path: "/receipt/",
    component: _3fc7fc1a,
    pathToRegexpOptions: {"strict":true},
    name: "receipt"
  }, {
    path: "/terms/",
    component: _ea78e182,
    pathToRegexpOptions: {"strict":true},
    name: "terms"
  }, {
    path: "/tire-guarantee/",
    component: _0a6060db,
    pathToRegexpOptions: {"strict":true},
    name: "tire-guarantee"
  }, {
    path: "/tire-repair/",
    component: _4927e1ca,
    pathToRegexpOptions: {"strict":true},
    name: "tire-repair"
  }, {
    path: "/under-construction/",
    component: _18e2d69e,
    pathToRegexpOptions: {"strict":true},
    name: "under-construction"
  }, {
    path: "/unfollow-account/",
    component: _b13edc3c,
    pathToRegexpOptions: {"strict":true},
    name: "unfollow-account"
  }, {
    path: "/delivery-enquete/complete/",
    component: _2ac25ee0,
    pathToRegexpOptions: {"strict":true},
    name: "delivery-enquete-complete"
  }, {
    path: "/mypage/edit/",
    component: _2c003b96,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-edit"
  }, {
    path: "/mypage/purchases/",
    component: _0a02c3b9,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases"
  }, {
    path: "/mypage/quotations/",
    component: _1002d0b6,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations"
  }, {
    path: "/mypage/vehicles/",
    component: _f7eaffd4,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles"
  }, {
    path: "/pit-reservation/complete/",
    component: _6ae5f206,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-complete"
  }, {
    path: "/pit-reservation/confirm/",
    component: _082f3c03,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-confirm"
  }, {
    path: "/pit-reservation/register/",
    component: _1d8c2a70,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-register"
  }, {
    path: "/pit-reservation/situation/",
    component: _0960e181,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-situation"
  }, {
    path: "/pit-reservation/store/",
    component: _11a80464,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-store"
  }, {
    path: "/pit-reservation/work/",
    component: _95fd0344,
    pathToRegexpOptions: {"strict":true},
    name: "pit-reservation-work"
  }, {
    path: "/quotations/complete/",
    component: _cdf02ace,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-complete"
  }, {
    path: "/quotations/details/",
    component: _d67640dc,
    pathToRegexpOptions: {"strict":true},
    name: "quotations-details"
  }, {
    path: "/receipt/complete/",
    component: _9be4b1b0,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-complete"
  }, {
    path: "/receipt/confirm/",
    component: _4c8b5aa1,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-confirm"
  }, {
    path: "/receipt/enquete/",
    component: _f72b756c,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-enquete"
  }, {
    path: "/receipt/qr/",
    component: _271987d3,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr"
  }, {
    path: "/receipt/register/",
    component: _64b3df92,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-register"
  }, {
    path: "/vehicles/registration/",
    component: _2a17e108,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration"
  }, {
    path: "/mypage/pit-reservation/complete/",
    component: _4917dacc,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-complete"
  }, {
    path: "/mypage/pit-reservation/confirm/",
    component: _0bd0b8ef,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-confirm"
  }, {
    path: "/mypage/pit-reservation/situation/",
    component: _aad25926,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-situation"
  }, {
    path: "/receipt/qr/read/",
    component: _82f3cab6,
    pathToRegexpOptions: {"strict":true},
    name: "receipt-qr-read"
  }, {
    path: "/vehicles/registration/complete/",
    component: _c3467e02,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-complete"
  }, {
    path: "/vehicles/registration/confirm/",
    component: _019629ea,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-confirm"
  }, {
    path: "/vehicles/registration/qr/",
    component: _00fd4467,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-qr"
  }, {
    path: "/vehicles/registration/select-type/",
    component: _5902d3d6,
    pathToRegexpOptions: {"strict":true},
    name: "vehicles-registration-select-type"
  }, {
    path: "/",
    component: _3e745dec,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/mypage/pit-reservation/:id?/",
    component: _48f49a69,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-pit-reservation-id"
  }, {
    path: "/mypage/purchases/:id/",
    component: _44b06061,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-purchases-id"
  }, {
    path: "/mypage/quotations/:id/",
    component: _df4ceec4,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-quotations-id"
  }, {
    path: "/mypage/vehicles/:id/",
    component: _0f458ffe,
    pathToRegexpOptions: {"strict":true},
    name: "mypage-vehicles-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
